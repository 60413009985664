export async function authLogin(username, password ) {

    console.log("********************* API authLogin ***************", username, password);


    const output = {
        data: {
            success:true,
            hash: "4e5c340e816bf0fed7fcad25005ce236"
        }
    }

    return output
}

export async function transfersList(hash) {

    console.log("********************* API transfersList ***************", hash);


    const output = {
        data:
        {
            success: true,
            clientFullTransfers: {
                client: {
                    id: 5,
                    name: "MARITIMA DOMINICANA ",
                    contact: "MARITIMA DOMINICANA*",
                    rncIdcard: "False",
                    email: "FCOMAS@MARDOM.COM",
                    phone: "+1 809-702-9398",
                    hash: "c5543f71c616bef13cf7f66d103e15b0"
                },
                fullTransfer: [

                    {
                        transfer: {
                            id: 5,
                            clientId: 5,
                            bl: "TSAC20241123",
                            startPlace: {
                                id: 2388924,
                                label: "Puerto Caucedo",
                                radius: 400,
                                latitude: 18.42505798,
                                longitude: -69.63572387,
                                address: "H, Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            endPlace: {
                                id: 2315359,
                                label: "Maritima Dominicana Caucedo",
                                radius: 286,
                                latitude: 18.44096788,
                                longitude: -69.65010703,
                                address: "Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            address: "Carretera Sanchez Km. 12 1/2, 11116, False",
                            city: "Santo Domingo",
                            note: "",
                            timeTravelEst: "2024-09-20 00:00:00",
                            timeRequest: "2024-09-21 13:37:40"
                        },
                        transferLnk: [
                            {
                                id: 353, transferId: 5, deviceId: 1007830, container: "TSAC20241123 - MRKU3406511",
                                currentState: 2, timeLinked: "2024-09-21 13:37:40", lastBlitTime: "2024-09-21 12:44:39"
                            },
                            {
                                id: 354, transferId: 5, deviceId: 3031660, container: "TSAC20241123 - MRKU5736390",
                                currentState: 2, timeLinked: "2024-09-21 13:37:40", lastBlitTime: "2024-09-23 18:23:45"
                            },
                            {
                                id: 355, transferId: 5, deviceId: 2895717, container: "TSAC20241123 - MRKU6305530",
                                currentState: 3, timeLinked: "2024-09-21 13:37:40", lastBlitTime: "2024-09-21 13:07:44"
                            },
                            {
                                id: 356, transferId: 5, deviceId: 865539, container: "TSAC20241123 - MRSU3479254",
                                currentState: 1, timeLinked: "2024-09-21 13:37:40", lastBlitTime: null
                            },
                            {
                                id: 363, transferId: 5, deviceId: 3076352, container: "TSAC20241123 - TEMU6882874",
                                currentState: 3, timeLinked: "2024-09-21 13:37:40", lastBlitTime: "2024-09-21 13:12:31"
                            }
                        ]
                    },
                    {
                        transfer: {
                            id: 3,
                            clientId: 5,
                            bl: "BQEGMXM058375",
                            startPlace: {
                                id: 1190063,
                                label: "Puerto Haina",
                                radius: 400,
                                latitude: 18.42351187,
                                longitude: -70.01320839,
                                address: "18.423512, -70.013208"
                            },
                            endPlace: {
                                id: 2315359,
                                label: "Maritima Dominicana Caucedo",
                                radius: 286,
                                latitude: 18.44096788,
                                longitude: -69.65010703,
                                address: "Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            address: "Carretera Sanchez Km. 12 1/2, 11116, False",
                            city: "Santo Domingo",
                            note: "",
                            timeTravelEst: "2024-09-20 00:00:00",
                            timeRequest: "2024-09-23 11:23:12"
                        },
                        transferLnk: [
                            { 
                                id: 386, transferId: 3, deviceId: 1076519, container: "BQEGMXM058375 - FSCU8216715", 
                                currentState: 3, timeLinked: "2024-09-23 11:23:12", lastBlitTime: "2024-09-23 13:34:22"
                            },
                            {
                                id: 387, transferId: 3, deviceId: 1076520, container: "BQEGMXM058375 - FSCU8216654",
                                currentState: 2, timeLinked: "2024-09-23 10:19:12", lastBlitTime: "2024-09-23 12:34:22"
                            },
                            {
                                id: 388, transferId: 3, deviceId: 1076521, container: "BQEGMXM058375 - FSCU821459",
                                currentState: 5, timeLinked: "2024-09-23 09:20:12", lastBlitTime: "2024-09-23 11:34:22"
                            }
                        ]
                    },
                    {
                        transfer: {
                            id: 1,
                            clientId: 5,
                            bl: "ABT56S021136",
                            startPlace: {
                                id: 2388924,
                                label: "Puerto Caucedo",
                                radius: 400,
                                latitude: 18.42505798,
                                longitude: -69.63572387,
                                address: "H, Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            endPlace: {
                                id: 2315343,
                                label: "Maritima Dominicana Haina",
                                radius: 150,
                                latitude: 18.42833662,
                                longitude: -70.00471638,
                                address: "Almacenes A4 MARDOM, Autopista 30 de Mayo, Santo Domingo, República Dominicana, 11107"
                            },
                            address: "Carretera Sanchez Km. 12 1/2, 11116, False",
                            city: "Santo Domingo",
                            note: "",
                            timeTravelEst: "2024-09-21 00:00:00",
                            timeRequest: "2024-09-23 15:08:33"
                        },
                        transferLnk: [
                            {
                                id: 409, transferId: 1, deviceId: 3076397, container: "ABT56S021136  - HLBU9982876",
                                currentState: 1, timeLinked: "2024-09-23 15:08:33", lastBlitTime: null
                            }
                        ]
                    },
                    {
                        transfer: {
                            id: 2,
                            clientId: 1,
                            bl: "JDBF91615",
                            startPlace: {
                                id: 2388924,
                                label: "Puerto Caucedo",
                                radius: 400,
                                latitude: 18.42505798,
                                longitude: -69.63572387,
                                address: "H, Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            endPlace: {
                                id: 1190063,
                                label: "Puerto Haina",
                                radius: 510,
                                latitude: 18.42351187,
                                longitude: -70.01320839,
                                address: "18.423512, -70.013208"
                            },
                            address: "Parque Industrial Zona Franca la Romana 1, La Romana",
                            city: "La Romana",
                            note: "DESDE DIF A LA ROMANA",
                            timeTravelEst: "2024-09-16 00:00:00",
                            timeRequest: "2024-09-16 11:11:19"
                        },
                        transferLnk: [
                            {
                                id: 1,
                                transferId: 2,
                                deviceId: 2850827,
                                container: "JDBF91615 - JDBF91615",
                                currentState: 2,
                                timeLinked: "2024-09-16 11:11:19",
                                lastBlitTime: null
                            },
                                                {
                                id: 1,
                                transferId: 2,
                                deviceId: 2850829,
                                container: "JDBF91615 - JDBF69816",
                                currentState: 2,
                                timeLinked: "2024-09-16 11:11:19",
                                lastBlitTime: null
                            }
                        ]
                    },
                    {
                        transfer: {
                            id: 49479,
                            clientId: 5,
                            bl: "BWLESP24001509",
                            startPlace: {
                                id: 2388924,
                                label: "Puerto Caucedo",
                                radius: 400,
                                latitude: 18.42505798,
                                longitude: -69.63572387,
                                address: "H, Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            endPlace: {
                                id: 2315359,
                                label: "Maritima Dominicana Caucedo",
                                radius: 286,
                                latitude: 18.44096788,
                                longitude: -69.65010703,
                                address: "Andrés, Santo Domingo, República Dominicana, 15700"
                            },
                            address: "Carretera Sanchez Km. 12 1/2, 11116, False",
                            city: "Santo Domingo",
                            note: "",
                            timeTravelEst: "2024-10-11 00:00:00",
                            timeRequest: "2024-10-12 10:41:32"
                        },
                        transferLnk: [
                            {
                                id: 1391, transferId: 49479, deviceId: 2907740, container: "BWLESP24001509 - MSNU7031174",
                                currentState: 1, timeLinked: "2024-10-12 10:41:32", lastBlitTime: null
                            },
                            {
                                id: 1392, transferId: 49479, deviceId: 3076359, container: "BWLESP24001509 - UETU6093661",
                                currentState: 2, timeLinked: "2024-10-12 10:41:32", lastBlitTime: "2024-10-12 11:06:27"
                            }
                        ]
                    }
                ]
            }

        }
    }


    return output
}



export async function transfersListFrame(hash, from , to) {

    console.log("********************* API transfersListFrame ***************", hash, from , to );


    const output = {
        data:{
    success: true,
    clientFullTransfers: {
        client: {
            id: 1,
            name: "ALLIED CIGAR LA ROMANA SAS",
            contact: "IMPERIAL TOBACCO LA ROMANA SAS",
            rncIdcard: "False",
            email: "mdejesus@syncrologistics.com",
            phone: "+1 829-521-8962",
            hash: "99b1e5368fc54a7ee3903c946b9682da"
        },
        fullTransfer: [
            {
                transfer: {
                    id: 22,
                    clientId: 1,
                    bl: "JDBF91615",
                    startPlace: {
                        id: 1190063,
                        label: "Puerto Caucedo",
                        radius: 510,
                        latitude: 18.42351187,
                        longitude: -70.01320839,
                        address: "18.423512, -70.013208"
                    },
                    endPlace: {
                        id: 1190063,
                        label: "Puerto Haina",
                        radius: 510,
                        latitude: 18.42351187,
                        longitude: -70.01320839,
                        address: "18.423512, -70.013208"
                    },
                    address: "Parque Industrial Zona Franca la Romana 1, La Romana",
                    city: "La Romana",
                    note: "DESDE DIF A LA ROMANA",
                    timeTravelEst: "2024-09-16 00:00:00",
                    timeRequest: "2024-09-16 11:11:19"
                },
                transferLnk: [
                    {
                        id: 1,
                        transferId: 22,
                        deviceId: 2850827,
                        container: "JDBF91615 - JDBF91615",
                        currentState: 2,
                        timeLinked: "2024-09-16 11:11:19",
                        lastBlitTime: null
                    },
                                        {
                        id: 1,
                        transferId: 22,
                        deviceId: 2850829,
                        container: "JDBF91615 - JDBF69816",
                        currentState: 2,
                        timeLinked: "2024-09-16 11:11:19",
                        lastBlitTime: null
                    }
                ]
            },
            {
                transfer: {
                    id: 33,
                    clientId: 4,
                    bl: "CWPS4M087424",
                    startPlace: {
                        id: 1190063,
                        label: "Puerto Haina",
                        radius: 510,
                        latitude: 18.42351187,
                        longitude: -70.01320839,
                        address: "18.423512, -70.013208"
                    },
                    endPlace: {
                        id: 1190063,
                        label: "Puerto Haina",
                        radius: 510,
                        latitude: 18.42351187,
                        longitude: -70.01320839,
                        address: "18.423512, -70.013208"
                    },
                    address: "PARQUE INDUSTRIAL ZONA FRANCA INDUSTRIAL II VILLA VERDE ROMANA, False",
                    city: "La Romana",
                    note: "",
                    timeTravelEst: "2024-09-13 00:00:00",
                    timeRequest: "2024-09-16 11:17:45"
                },
                transferLnk: [
                    {
                        id: 2,
                        transferId: 33,
                        deviceId: 3076448,
                        container: "CWPS4M087424 - CMCU6044892",
                        currentState: 1,
                        timeLinked: "2024-09-16 11:17:45",
                        lastBlitTime: "2024-09-27 20:31:09"
                    },
                                        {
                        id: 2,
                        transferId: 33,
                        deviceId: 3076448,
                        container: "CWPS4M087424 - CMCU6044893",
                        currentState: 2,
                        timeLinked: "2024-09-16 11:17:45",
                        lastBlitTime: "2024-09-27 20:31:09"
                    },
                    {
                        id: 2,
                        transferId: 33,
                        deviceId: 3076448,
                        container: "CWPS4M087424 - CMCU6044894",
                        currentState: 3,
                        timeLinked: "2024-09-16 11:17:45",
                        lastBlitTime: "2024-09-27 20:31:09"
                    }
                ]
            },
            {
                transfer: {
                    id: 0,
                    clientId: 21,
                    bl: "SMLU8253381A",
                    startPlace: {
                        id: 1190063,
                        label: "Puerto Haina",
                        radius: 510,
                        latitude: 18.42351187,
                        longitude: -70.01320839,
                        address: "18.423512, -70.013208"
                    },
                    endPlace: null,
                    address: "MINASO II, BIBLIOTECA NACIONAL, EL MILLON, EL MILLON, SANTO DOMINGO, R.D.",
                    city: "Santiago",
                    note: "",
                    timeTravelEst: "2024-10-29 00:00:00",
                    timeRequest: "2024-10-29 14:10:34"
                },
                transferLnk: [
                    {
                        id: 6332,
                        transferId: 0,
                        deviceId: 3144296,
                        container: "SMLU8253381A - SMLU7966396",
                        currentState: 6,
                        timeLinked: "2024-10-29 14:10:34",
                        lastBlitTime: null
                    }
                ]
            }
        ]
    }
}
    }


    return output
}

export async function statesCountGeneral(hash) {

    console.log("********************* API statesCountGeneral ***************", hash);


    const output = {
        data: {
            success: true,
            states: [
                { id: 1, name: "LINKED", count: 3 },

                { id: 2, name: "IN_TRANSIT", count: 4 },

                { id: 3, name: "DONE", count: 3 },

                { id: 4, name: "CANCELED", count: 0 },

                { id: 5, name: "EXPIRED", count: 8 },

                { id: 6, name: "START_END_ERROR", count: 2 }
            ]
        }
    }

    return output
}

export async function candadoList(hash){
    console.log("********************** API candadoList *************", hash);

    const output={
        data:{

            success: true,
            devicesGroupsDevicesStates: [

            {
                device: {
                    id: 644657, sourceId: 417317, deviceid: "7591116296", model: "jointech_jt701", 
                    label: "7591116296/ASSZX408352 - SEKU5765721/HAYCO DOMINICAN REPUBLI", groupId: 129346, created: "2020-05-15 00:00:00"
                },
                group: {
                    id: 129346, title: "DISPONIBLE", color: "009688"
                },
                deviceState: {
                    deviceId: 644657, sourceId: 417317, gpsUpdated: "2024-11-13 13:42:17", gpsSignalLevel: 75,
                    lat: 18.463476666666665, lng: -69.71110666666667, connectionStatus: "active", movementStatus: "parked",
                    lastUpdate: "2024-11-13 13:42:18", gsmUpdated: "2024-11-13 13:42:17", gsmSignalLevel: 90, batteryLevel: 95,
                    batteryUpdate: "2024-11-13 13:42:17", lockStatus: "ready_for_command", lockStatusUpdated: "2024-11-13 13:42:17",
                    lockState: "sealed", lockStateUpdated: "2024-11-13 13:42:17", lockCommandResult: "ok", lockCommandResultUpdated: "2024-11-13 10:07:46",
                    hardwareKey: "0006471762", hardwareKeyUpdated: "2024-11-12 15:06:44", actualTrackUpdate: "2024-11-13 13:42:17"
                }
            },
            {
                device: {
                    id: 642727, sourceId: 416159, deviceid: "7591116334", model: "jointech_jt701",
                    label: "7591116334/HKA2462156 - BEAU5646665/GLOBAL STORAGE SRL", groupId: 129346, created: "2020-05-08 00:00:00"
                },
                group: { 
                    id: 129346, title: "ENLACES ACTIVOS", color: "009688" 
                },
                deviceState: {
                    deviceId: 642727, sourceId: 416159, gpsUpdated: "2024-11-13 13:30:06", gpsSignalLevel: 37,
                    lat: 18.42732, lng: -69.99635166666667, connectionStatus: "idle", movementStatus: "parked",
                    lastUpdate: "2024-11-13 13:33:17", gsmUpdated: "2024-11-13 13:30:06", gsmSignalLevel: 100, batteryLevel: 40, 
                    batteryUpdate: "2024-11-13 13:30:06", lockStatus: "ready_for_command", lockStatusUpdated: "2024-11-13 13:30:06",
                    lockState: "sealed", lockStateUpdated: "2024-11-13 13:30:06", lockCommandResult: "ok", lockCommandResultUpdated: "2024-11-12 14:19:16",
                    hardwareKey: "0006472887", hardwareKeyUpdated: "2024-10-01 09:21:10", actualTrackUpdate: "2024-11-13 13:27:06"
                }
            },
            {
                device: {
                    id: 642727, sourceId: 416159, deviceid: "7591116334", model: "jointech_jt701",
                    label: "7591116334/HKA2462156 - BEAU5646665/GLOBAL STORAGE SRL", groupId: 129346, created: "2020-05-08 00:00:00"
                },
                group: { 
                    id: 129346, title: "ENLACES ACTIVOS", color: "009688" 
                },
                deviceState: {
                    deviceId: 642727, sourceId: 416159, gpsUpdated: "2024-11-13 13:30:06", gpsSignalLevel: 37,
                    lat: 18.42732, lng: -69.99635166666667, connectionStatus: "offline", movementStatus: "parked",
                    lastUpdate: "2024-11-13 13:33:17", gsmUpdated: "2024-11-13 13:30:06", gsmSignalLevel: 100, batteryLevel: 100, 
                    batteryUpdate: "2024-11-13 13:30:06", lockStatus: "ready_for_command", lockStatusUpdated: "2024-11-13 13:30:06",
                    lockState: "sealed", lockStateUpdated: "2024-11-13 13:30:06", lockCommandResult: "ok", lockCommandResultUpdated: "2024-11-12 14:19:16",
                    hardwareKey: "0006472887", hardwareKeyUpdated: "2024-10-01 09:21:10", actualTrackUpdate: "2024-11-13 13:27:06"
                }
            }
            ]
        }
        
    }

return output
}

export async function devicesAllStats(hash) {

    console.log("********************* API devicesAllStats ***************", hash);


    const output = {
        data: {
            success: true,

            deviceStateStats: {
                onLine: 30,

                offline: 25,
                batteryStat: {
                    q1: 20,
                    q2: 6,
                    q3: 6,
                    q4: 32,
                    total: 44
                },
                gsmSignalStat: {
                    q1: 5,
                    q2: 9,
                    q3: 5,
                    q4: 25,
                    total: 300
                },
                gpsSignalStat: {
                    q1: 1,
                    q2: 13,
                    q3: 10,
                    q4: 31,
                    total: 300
                },
                groupsStats: [
                {
                    groupName: "Operadores",
                    qty: 0
                },
                {
                    groupName: "ENLACES ACTIVOS",
                    qty: 98
                },
                {
                    groupName: "Fuera de Servicio",
                    qty: 0
                },
                {
                    groupName: "SIN ASIGNAR",
                    qty: 60
                },
                {
                    groupName: "mensajero",
                    qty: 0
                },
                {
                    groupName: "COLMADO IDEAL",
                    qty: 0
                },
                {
                    groupName: "CANDADOS A ELIMINAR",
                    qty: 0
                },
                {
                    groupName: "None",
                    qty: 1
            }
        ]
    }


        }
    }

    return output
}

export async function statesCountGeneralFrame(hash, from, to ) {

    console.log("********************* API statesCountGeneralFrame ***************", hash, from, to);


    const output = {
        data: {
            success: true,
            states: [
                { id: 1, name: "LINKED", count: 1 },

                { id: 2, name: "IN_TRANSIT", count: 1 },

                { id: 3, name: "DONE", count: 1 },

                { id: 4, name: "CANCELED", count: 0 },

                { id: 5, name: "EXPIRED", count: 1 },

                { id: 6, name: "START_END_ERROR", count: 3 }
            ]
        }
    }

    return output
}


export async function transferBlitslist(hash, transfer_id) {


    console.log("********************* API transferBlitslist ***************", hash, transfer_id);

const output1={
    data:{
        success:true,
        clientFullTransferBlits:{
            client:{
                id:6,
                name:"CUTLER HAMMER INDUSTRIES LTD",
                contact:"CUTLER HAMMER INDUSTRIES LTD",
                rncIdcard:"False",
                email:"yanguillenlyl@gmail.com",
                phone:"1",
                hash:"34fbfb4eb05085ed8c27b3be3dd949dc"
            },
            fullTransferBlits:{
                transfer:{
                    id:47955,
                    clientId:6,
                    bl:"BQEGMXM058375",
                    startPlace:{
                        id:2388925,
                        label:"Puerto Haina",
                        radius:101,
                        latitude:18.4691788,
                        longitude:-69.9566977,
                        address:"Dirección General de Aduanas Puerto de Sans Soucí, República Dominicana, 10210"
                    },
                    endPlace:{
                        id:2388926,
                        label:"Maritima Dominicana Caucedo",
                        radius:670,
                        latitude:18.50135,
                        longitude:-69.7791422,
                        address:"Calle Lateral 5, Parque Industrial Itabo, San Gregorio de Nigua, San Cristóbal, República Dominicana, 00809"
                    },
                    address:"SANTO DOMINGO, False",
                    city:"San Cristóbal",
                    note:"",
                    timeTravelEst:"2024-03-23 00:00:00",
                    timeRequest:"2024-09-27 16:23:41"
                },
                transferLnkBlits:[
                    {
                        transferLinked:{
                            id:581,
                            transferId:47955,
                            deviceId:1073543,
                            container:"FSCU821459",
                            currentState:5,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat:   18.47439, lng: -69.9172266, heading:0, completed:  0},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4755344, lng:   -69.91564, heading:0, completed:  0},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4762777, lng: -69.9135955, heading:0, completed:  0},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4762533, lng: -69.9112088, heading:0, completed:  0},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4759344, lng: -69.9088577, heading:0, completed:  0},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4765033, lng: -69.9048977, heading:0, completed: 19},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4860455, lng: -69.8778222, heading:0, completed: 37},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4855733, lng: -69.8739111, heading:0, completed: 39},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4854333, lng:   -69.87208, heading:0, completed: 40},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4845711, lng:    -69.8612, heading:0, completed: 46},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4844433, lng: -69.8594266, heading:0, completed: 47},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4843811, lng: -69.8575422, heading:0, completed: 48},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4845088, lng: -69.8556133, heading:0, completed: 50},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4848266, lng: -69.8535822, heading:0, completed: 51},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat:   18.48481, lng:   -69.85144, heading:0, completed: 52},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4848955, lng: -69.8451688, heading:0, completed: 53},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4841611, lng: -69.8414444, heading:0, completed: 55},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4840466, lng: -69.8393333, heading:0, completed: 53},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4832211, lng: -69.8281422, heading:0, completed: 61},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4826788, lng: -69.8099511, heading:0, completed: 68},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4827922, lng: -69.8042533, heading:0, completed: 71},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat:   18.48307, lng: -69.7904488, heading:0, completed: 79},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4827766, lng: -69.7783422, heading:0, completed: 86},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat:   18.48309, lng: -69.7777022, heading:0, completed: 78},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.4841077, lng: -69.7779377, heading:0, completed: 87},
                            { statusId:2, id:614790, trLnkId: 581, deviceId: 875156, lat: 18.5023444, lng:   -69.77556, heading:0, completed: 88}
                        ]
                    },
                    {
                        transferLinked:{
                            id:581,
                            transferId:47955,
                            deviceId:1073543,
                            container:"FSCU8216715",
                            currentState:3,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4691788, lng: -69.9566977,  heading: 350, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4695111, lng: -69.9567866,  heading: 348, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4702011, lng: -69.9569466,  heading: 347, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4707566, lng: -69.9570711,  heading: 349, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4714377, lng: -69.9572222,  heading: 347, completed:  3 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4724488, lng: -69.9574666,  heading: 351, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4721655, lng: -69.9574266,  heading: 164, completed:  3 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4716211, lng: -69.9573066,  heading: 165, completed:  3 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4709722, lng: -69.9571466,  heading: 168, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.47035,   lng: -69.9570177,  heading: 167, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4697155, lng: -69.95688,    heading: 166, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4692822, lng: -69.9567644,  heading: 168, completed:  2 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4660411, lng: -69.9539777,  heading: 167, completed: 11 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.46439,   lng: -69.9539955,  heading: 175, completed: 12 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4636588, lng: -69.9537066,  heading: 159, completed: 12 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4631577, lng: -69.9535155,  heading: 160, completed: 12 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4623677, lng: -69.9532177,  heading: 160, completed: 13 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4615555, lng: -69.95292,    heading: 160, completed: 13 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4612444, lng: -69.9524666,  heading:  68, completed: 14 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4615455, lng: -69.9517155,  heading:  69, completed: 14 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4617877, lng: -69.9510666,  heading:  71, completed: 14 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4620555, lng: -69.9502711,  heading:  70, completed: 15 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4622844, lng: -69.9495333,  heading:  70, completed: 15 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4619422, lng: -69.9493333,  heading: 160, completed: 15 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4612711, lng: -69.94908,    heading: 159, completed: 15 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4607433, lng: -69.9488755,  heading: 159, completed: 16 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4603555, lng: -69.9487288,  heading: 158, completed: 16 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4598366, lng: -69.9485155,  heading: 153, completed: 16 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4597566, lng: -69.9484711,  heading: 150, completed: 16 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.45945,   lng: -69.9483555,  heading: 160, completed: 16 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4588866, lng: -69.9481422,  heading: 161, completed: 17 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.45805,   lng: -69.9478311,  heading: 162, completed: 17 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4576888, lng: -69.9476177,  heading:  87, completed: 17 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4579333, lng: -69.9468711,  heading:  71, completed: 18 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4582822, lng: -69.9457866,  heading:  72, completed: 18 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4587044, lng: -69.9444711,  heading:  71, completed: 19 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4591422, lng: -69.9430711,  heading:  72, completed: 19 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4613955, lng: -69.9384444,  heading:  61, completed: 22 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4685666, lng: -69.9242844,  heading:  61, completed: 29 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4724677, lng: -69.9193511,  heading:  45, completed: 32 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4762633, lng: -69.9117822,  heading:  96, completed: 35 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4768911, lng: -69.9042666,  heading:  54, completed: 39 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4820233, lng: -69.8961999,  heading:  38, completed: 43 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4859922, lng: -69.8883111,  heading:  53, completed: 47 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4859788, lng: -69.8784177,  heading:  95, completed: 52 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4845922, lng: -69.8617777,  heading:  94, completed: 59 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4848744, lng: -69.8516844,  heading: 108, completed: 63 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4854777, lng:  -69.84292,   heading:  75, completed: 67 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.48679,   lng: -69.8349688,  heading:  74, completed: 71 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4881055, lng: -69.8299466,  heading:  72, completed: 73 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4894066, lng: -69.8249511,  heading:  74, completed: 75 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4918955, lng: -69.81536,    heading:  73, completed: 80 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4934311, lng: -69.8095866,  heading:  73, completed: 83 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.49548,   lng: -69.8016666,  heading:  75, completed: 86 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4969366, lng: -69.7958755,  heading:  74, completed: 89 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.4993111, lng: -69.7868177,  heading:  71, completed: 93 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.50135,   lng: -69.7791422,  heading:  76, completed: 96 },
                            { statusId:2, id:614790, trLnkId:581,  deviceId:  875156, lat: 18.5019877, lng: -69.7767644,  heading:  75, completed:100 }

                        ]
                    },

                ]
            }
        }
    }
 

}
const output2={
    data:{
        success:true,
        clientFullTransferBlits:{
            client:{
                id:6,
                name:"CUTLER HAMMER INDUSTRIES LTD",
                contact:"CUTLER HAMMER INDUSTRIES LTD",
                rncIdcard:"False",
                email:"yanguillenlyl@gmail.com",
                phone:"1",
                hash:"34fbfb4eb05085ed8c27b3be3dd949dc"
            },
            fullTransferBlits:{
                transfer:{
                    id:48091,
                    clientId:6,
                    bl:"ABT56S021136",
                    startPlace:{
                        id:2388925,
                        label:"Puerto Caucedo",
                        radius:101,
                        latitude:18.429535,
                        longitude:-69.6384816,
                        address:"Dirección General de Aduanas Puerto de Sans Soucí, República Dominicana, 10210"
                    },
                    endPlace:{
                        id:2388926,
                        label:"Maritima Dominicana Haina",
                        radius:670,
                        latitude:18.50135,
                        longitude:-69.7791422,
                        address:"Calle Lateral 5, Parque Industrial Itabo, San Gregorio de Nigua, San Cristóbal, República Dominicana, 00809"
                    },
                    address:"SANTO DOMINGO, False",
                    city:"San Cristóbal",
                    note:"",
                    timeTravelEst:"2024-03-23 00:00:00",
                    timeRequest:"2024-09-27 16:23:41"
                },
                transferLnkBlits:[
                    {
                        transferLinked:{
                            id:581,
                            transferId:48091,
                            deviceId:1073543,
                            container:"HLBU9982876",
                            currentState:1,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { deviceId: 1905476, lat: 18.429535, lng: -69.6384816, heading: 0, statusId: 2, completed: 1 },
                            { deviceId: 1905476, lat: 18.429625, lng: -69.6385333, heading: 0, statusId: 2, completed: 1 },
                            { deviceId: 1905476, lat: 18.429655, lng: -69.6385083, heading: 36, statusId: 2, completed: 2 },
                            { deviceId: 1905476, lat: 18.4299983,lng: -69.6377483, heading: 34, statusId: 2, completed: 2 },
                            { deviceId: 1905476, lat: 18.4304483, lng: -69.6361233, heading: 46, statusId: 2, completed: 2 },
                            { deviceId: 1905476, lat: 18.43065, lng: -69.6350683, heading: 146, statusId: 2, completed: 3 },
                            { deviceId: 1905476, lat: 18.4304466, lng: -69.6342083, heading: 22, statusId: 2, completed: 3 },
                            { deviceId: 1905476, lat: 18.430895, lng: -69.6340016, heading: 284, statusId: 2, completed: 4 },
                            { deviceId: 1905476, lat: 18.4311883, lng: -69.6342899, heading: 264, statusId: 2, completed: 4 },
                            { deviceId: 1905476, lat: 18.43121, lng: -69.6347416, heading: 264, statusId: 2, completed: 4 },
                            { deviceId: 1905476, lat: 18.4311416, lng: -69.6361766, heading: 264, statusId: 2, completed: 5 },
                            { deviceId: 1905476, lat: 18.4311533, lng: -69.63622, heading: 0, statusId: 2, completed: 6  },
                            { deviceId: 1905476, lat: 18.431165, lng: -69.636235, heading: 0, statusId: 2, completed: 6 },
                            { deviceId: 1905476, lat: 18.4311733, lng: -69.6362216, heading: 0, statusId: 2, completed: 7 },
                            { deviceId: 1905476, lat: 18.431155, lng: -69.636235, heading: 0, statusId: 2, completed: 7 },
                            { deviceId: 1905476, lat: 18.431165, lng: -69.6362366, heading: 0, statusId: 2,  completed: 8 },
                            { deviceId: 1905476, lat: 18.431165, lng: -69.6362366, heading: 0, statusId: 2, completed: 8 },
                            { deviceId: 1905476, lat: 18.4311466, lng: -69.6362516, heading: 0, statusId: 2, completed: 8 },
                            { deviceId: 1905476, lat: 18.431155, lng: -69.6362483, heading: 0, statusId: 2, completed: 8 },
                            { deviceId: 1905476, lat: 18.4311766, lng: -69.6362266, heading: 0, statusId: 2, completed: 9 },
                            { deviceId: 1905476, lat: 18.4312233, lng: -69.6361666, heading: 0, statusId: 2, completed: 9 },
                            { deviceId: 1905476, lat: 18.4312183, lng: -69.6362, heading: 266, statusId: 2, completed: 9 },
                            { deviceId: 1905476, lat: 18.4312033, lng: -69.6364183, heading: 0, statusId: 2, completed: 9 },
                            { deviceId: 1905476, lat: 18.4311983, lng: -69.6364466, heading: 0,  statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.4311933, lng: -69.636455,  heading: 0, statusId: 2, completed: 10  },
                            { deviceId: 1905476, lat: 18.4311866, lng: -69.6364566,  heading: 0, statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.43118, lng: -69.63645, heading: 0, statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.4311716, lng: -69.6364716, heading: 0, statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.4311883, lng: -69.6364983, heading: 0, statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.4311916, lng: -69.6365, heading: 0, statusId: 2, completed: 10 },
                            { deviceId: 1905476, lat: 18.4311833, lng: -69.6364966, heading: 0, statusId: 2, completed: 11 },
                            { deviceId: 1905476, lat: 18.4311783, lng: -69.63649, heading: 0, statusId: 2, completed: 12 },
                            { deviceId: 1905476, lat: 18.43118, lng: -69.6372866, heading: 270, statusId: 2, completed: 13 },
                            { deviceId: 1905476, lat: 18.4311833, lng: -69.63781, heading: 270, statusId: 2, completed: 14 },
                            { deviceId: 1905476, lat: 18.431175, lng: -69.6383066, heading: 264, statusId: 2, completed: 15 },
                            { deviceId: 1905476, lat: 18.4311716, lng: -69.6364716, heading: 0, statusId: 2, completed: 16 },
                            { deviceId: 1905476, lat: 18.4311883, lng: -69.6364983, heading: 0, statusId: 2, completed: 17 },
                            { deviceId: 1905476, lat: 18.4311916, lng: -69.6365, heading: 0, statusId: 2, completed: 18 },
                            { deviceId: 1905476, lat: 18.4311833, lng: -69.6364966, heading: 0, statusId: 2, completed: 19 },
                            { deviceId: 1905476, lat: 18.4311783, lng: -69.63649, heading: 0, statusId: 2, completed: 20 },
                            { deviceId: 1905476, lat: 18.43118, lng: -69.6372866, heading: 270, statusId: 2, completed: 21 },
                            { deviceId: 1905476, lat: 18.4311833, lng: -69.63781, heading: 270, statusId: 2, completed: 23 },
                            { deviceId: 1905476, lat: 18.431175, lng: -69.6383066, heading: 264, statusId: 2, completed: 24 },
                            { deviceId: 1905476, lat: 18.43111, lng: -69.63874, heading: 258, statusId: 2, completed: 25 },
                            { deviceId: 1905476, lat: 18.4310883, lng: -69.638915, heading: 0, statusId: 2, completed: 26 },
                            { deviceId: 1905476, lat: 18.4310883, lng: -69.638915, heading: 0, statusId: 2, completed: 27 },
                            { deviceId: 1905476, lat: 18.43108, lng: -69.6389966, heading: 262, statusId: 2, completed: 28 },
                            { deviceId: 1905476, lat: 18.43103, lng: -69.6394033, heading: 266,  statusId: 2, completed: 29 },
                            { deviceId: 1905476, lat: 18.43106, lng: -69.6399983, heading: 284, statusId: 2, completed: 30 },
                            { deviceId: 1905476, lat: 18.4311633, lng: -69.6405566, heading: 276, statusId: 2, completed: 31 },
                            { deviceId: 1905476, lat: 18.4312433, lng: -69.641615, heading: 270, statusId: 2, completed: 32 },
                            { deviceId: 1905476, lat: 18.4309333, lng: -69.64433, heading: 250, statusId: 2, completed: 33 },
                            { deviceId: 1905476, lat: 18.429485, lng: -69.6478116, heading: 250, statusId: 2, completed: 34 },
                            { deviceId: 1905476, lat: 18.4498366, lng: -69.6683566, heading: 272,  statusId: 2,  completed: 35 },
                            { deviceId: 1905476, lat: 18.4502216, lng: -69.6742816, heading: 272, statusId: 2, completed: 36 },
                            { deviceId: 1905476, lat: 18.4506783, lng: -69.6803433, heading: 272, statusId: 2, completed: 37 },
                            { deviceId: 1905476, lat: 18.4512016, lng: -69.6862433, heading: 284, statusId: 2, completed: 38 },
                            { deviceId: 1905476, lat: 18.4531583, lng: -69.6912583, heading: 286, statusId: 2, completed: 39 },
                            { deviceId: 1905476, lat: 18.454745, lng: -69.6964633, heading: 284, statusId: 2, completed: 41 },
                            { deviceId: 1905476, lat: 18.455695, lng: -69.7024716, heading: 282, statusId: 2, completed: 42 },
                            { deviceId: 1905476, lat: 18.4560733, lng: -69.708385, heading: 264, statusId: 2, completed: 43 },
                            { deviceId: 1905476, lat: 18.4565166, lng: -69.7144433, heading: 282, statusId: 2, completed: 44 },
                            { deviceId: 1905476, lat: 18.458335, lng: -69.71999, heading: 284, statusId: 2,  completed: 45 },
                            { deviceId: 1905476, lat: 18.4598766, lng: -69.725795, heading: 284, statusId: 2,  completed: 46 },
                            { deviceId: 1905476, lat: 18.4643766, lng: -69.7663283, heading: 272, statusId: 2, completed: 47 },
                            { deviceId: 1905476, lat: 18.465, lng: -69.772135, heading: 276, statusId: 2, completed: 48 },
                            { deviceId: 1905476, lat: 18.4653966, lng: -69.7779233, heading: 274, statusId: 2, completed: 49 },
                            { deviceId: 1905476, lat: 18.4661866, lng: -69.7836733, heading: 272, statusId: 2, completed: 50 }   

                        ]
                    },

                                        

                ]
            }
        }
    }
 

}

const output3={
    data:{
        success:true,
        clientFullTransferBlits:{
            client:{
                id:6,
                name:"CUTLER HAMMER INDUSTRIES LTD",
                contact:"CUTLER HAMMER INDUSTRIES LTD",
                rncIdcard:"False",
                email:"yanguillenlyl@gmail.com",
                phone:"1",
                hash:"34fbfb4eb05085ed8c27b3be3dd949dc"
            },
            fullTransferBlits:{
                transfer:{
                    id:49479,
                    clientId:6,
                    bl:"BWLESP24001509",
                    startPlace:{
                        id:2388925,
                        label:"Puerto Caucedo",
                        radius:101,
                        latitude:18.4669766,
                        longitude:-69.9561822,
                        address:"Dirección General de Aduanas Puerto de Sans Soucí, República Dominicana, 10210"
                    },
                    endPlace:{
                        id:2388926,
                        label:"Maritima Dominicana Caucedo",
                        radius:670,
                        latitude:18.50135,
                        longitude:-69.7791422,
                        address:"Calle Lateral 5, Parque Industrial Itabo, San Gregorio de Nigua, San Cristóbal, República Dominicana, 00809"
                    },
                    address:"SANTO DOMINGO, False",
                    city:"San Cristóbal",
                    note:"",
                    timeTravelEst:"2024-03-23 00:00:00",
                    timeRequest:"2024-09-27 16:23:41"
                },
                transferLnkBlits:[
                    {
                        transferLinked:{
                            id:581,
                            transferId:49479,
                            deviceId:1073543,
                            container:"MSNU7031174",
                            currentState:1,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { statusId:2, deviceId: 875156, lat: 18.4662666, lng: -69.9561022, heading: 125, completed:  1}, 
                            { statusId:2, deviceId: 875156, lat: 18.4662666, lng: -69.9561022, heading: 125, completed:  2}, 
                            { statusId:2, deviceId: 875156, lat: 18.4669766, lng: -69.9561822, heading: 347, completed:  3}, 
                            { statusId:2, deviceId: 875156, lat: 18.4676822, lng: -69.9563688, heading: 348, completed: 4}, 
                            { statusId:2, deviceId: 875156, lat: 18.46829,   lng: -69.9564977, heading: 346, completed: 5}, 
                            { statusId:2, deviceId: 875156, lat: 18.46858,   lng: -69.9565733, heading: 344, completed: 6}, 
                            { statusId:2, deviceId: 875156, lat: 18.4691788, lng: -69.9566977, heading: 350, completed: 7}, 
                            { statusId:2, deviceId: 875156, lat: 18.4695111, lng: -69.9567866, heading: 348, completed: 8}, 
                            { statusId:2, deviceId: 875156, lat: 18.4698166, lng: -69.9568577, heading: 346, completed: 9}, 
                            { statusId:2, deviceId: 875156, lat: 18.4702011, lng: -69.9569466, heading: 347, completed: 10}, 
                            { statusId:2, deviceId: 875156, lat: 18.4707566, lng: -69.9570711, heading: 349, completed: 11}, 
                            { statusId:2, deviceId: 875156, lat: 18.4714377, lng: -69.9572222, heading: 347, completed: 12}, 
                            { statusId:2, deviceId: 875156, lat: 18.4724488, lng: -69.9574666, heading: 351, completed: 13} 

                        ]
                    },
                    {
                        transferLinked:{
                            id:581,
                            transferId:49479,
                            deviceId:1073543,
                            container:"UETU6093661",
                            currentState:2,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { statusId:2, deviceId:875156, lat: 18.4612444, lng: -69.9524666, heading:  68, completed: 14},
                            { statusId:2, deviceId:875156, lat: 18.4615455, lng: -69.9517155, heading:  69, completed: 14},
                            { statusId:2, deviceId:875156, lat: 18.4617877, lng: -69.9510666, heading:  71, completed: 14},
                            { statusId:2, deviceId:875156, lat: 18.4620555, lng: -69.9502711, heading:  70, completed: 15},
                            { statusId:2, deviceId:875156, lat: 18.4622844, lng: -69.9495333, heading:  70, completed: 15},
                            { statusId:2, deviceId:875156, lat: 18.4619422, lng: -69.9493333, heading: 160, completed: 15},
                            { statusId:2, deviceId:875156, lat: 18.4612711, lng:   -69.94908, heading: 159, completed: 15},
                            { statusId:2, deviceId:875156, lat: 18.4607433, lng: -69.9488755, heading: 159, completed: 16},
                            { statusId:2, deviceId:875156, lat: 18.4603555, lng: -69.9487288, heading: 158, completed: 16},
                            { statusId:2, deviceId:875156, lat: 18.4598366, lng: -69.9485155, heading: 153, completed: 16},
                            { statusId:2, deviceId:875156, lat: 18.4597566, lng: -69.9484711, heading: 150, completed: 16},
                            { statusId:2, deviceId:875156, lat:   18.45945, lng: -69.9483555, heading: 160, completed: 16},
                            { statusId:2, deviceId:875156, lat: 18.4588866, lng: -69.9481422, heading: 161, completed: 17},
                            { statusId:2, deviceId:875156, lat:   18.45805, lng: -69.9478311, heading: 162, completed: 17},
                            { statusId:2, deviceId:875156, lat: 18.4576888, lng: -69.9476177, heading:  87, completed: 17},
                            { statusId:2, deviceId:875156, lat: 18.4579333, lng: -69.9468711, heading:  71, completed: 18},
                            { statusId:2, deviceId:875156, lat: 18.4582822, lng: -69.9457866, heading:  72, completed: 18},
                            { statusId:2, deviceId:875156, lat: 18.4587044, lng: -69.9444711, heading:  71, completed: 19},
                            { statusId:2, deviceId:875156, lat: 18.4591422, lng: -69.9430711, heading:  72, completed: 19},
                            { statusId:2, deviceId:875156, lat: 18.4613955, lng: -69.9384444, heading:  61, completed: 22},
                            { statusId:2, deviceId:875156, lat: 18.4685666, lng: -69.9242844, heading:  61, completed: 29},
                            { statusId:2, deviceId:875156, lat: 18.4724677, lng: -69.9193511, heading:  45, completed: 32},
                            { statusId:2, deviceId:875156, lat: 18.4762633, lng: -69.9117822, heading:  96, completed: 35},
                            { statusId:2, deviceId:875156, lat: 18.4768911, lng: -69.9042666, heading:  54, completed: 39},
                            { statusId:2, deviceId:875156, lat: 18.4820233, lng: -69.8961999, heading:  38, completed: 43},
                            { statusId:2, deviceId:875156, lat: 18.4859922, lng: -69.8883111, heading:  53, completed: 47},
                            { statusId:2, deviceId:875156, lat: 18.4859788, lng: -69.8784177, heading:  95, completed: 52},
                            { statusId:2, deviceId:875156, lat: 18.4845922, lng: -69.8617777, heading:  94, completed: 59},
                            { statusId:2, deviceId:875156, lat: 18.4848744, lng: -69.8516844, heading: 108, completed: 63},
                            { statusId:2, deviceId:875156, lat: 18.4854777, lng:   -69.84292, heading:  75, completed: 67},
                            { statusId:2, deviceId:875156, lat:   18.48679, lng: -69.8349688, heading:  74, completed: 71},
                            { statusId:2, deviceId:875156, lat: 18.4881055, lng: -69.8299466, heading:  72, completed: 73},
                            { statusId:2, deviceId:875156, lat: 18.4894066, lng: -69.8249511, heading:  74, completed: 75},
                            { statusId:2, deviceId:875156, lat: 18.4918955, lng:   -69.81536, heading:  73, completed: 80},
                            { statusId:2, deviceId:875156, lat: 18.4934311, lng: -69.8095866, heading:  73, completed: 83},
                            { statusId:2, deviceId:875156, lat:   18.49548, lng: -69.8016666, heading:  75, completed: 86},
                            { statusId:2, deviceId:875156, lat: 18.4969366, lng: -69.7958755, heading:  74, completed: 89},
                            { statusId:2, deviceId:875156, lat: 18.4993111, lng: -69.7868177, heading:  71, completed: 93},
                            { statusId:2, deviceId:875156, lat:   18.50135, lng: -69.7791422, heading:  76, completed: 96},
                            { statusId:2, deviceId:875156, lat: 18.5019877, lng: -69.7767644, heading:  75, completed: 97}
                        ]
                    },

                                        

                ]
            }
        }
    }
 

}

const output4={
    data:{
        success:true,
        clientFullTransferBlits:{
            client:{
                id:6,
                name:"CUTLER HAMMER INDUSTRIES LTD",
                contact:"CUTLER HAMMER INDUSTRIES LTD",
                rncIdcard:"False",
                email:"yanguillenlyl@gmail.com",
                phone:"1",
                hash:"34fbfb4eb05085ed8c27b3be3dd949dc"
            },
            fullTransferBlits:{
                transfer:{
                    id:49479,
                    clientId:6,
                    bl:"BWLESP24001509",
                    startPlace:{
                        id:2388925,
                        label:"Puerto Caucedo",
                        radius:101,
                        latitude:18.4669766,
                        longitude:-69.9561822,
                        address:"Dirección General de Aduanas Puerto de Sans Soucí, República Dominicana, 10210"
                    },
                    endPlace:{
                        id:2388926,
                        label:"Maritima Dominicana Caucedo",
                        radius:670,
                        latitude:18.50135,
                        longitude:-69.7791422,
                        address:"Calle Lateral 5, Parque Industrial Itabo, San Gregorio de Nigua, San Cristóbal, República Dominicana, 00809"
                    },
                    address:"SANTO DOMINGO, False",
                    city:"San Cristóbal",
                    note:"",
                    timeTravelEst:"2024-03-23 00:00:00",
                    timeRequest:"2024-09-27 16:23:41"
                },
                transferLnkBlits:[
                    {
                        transferLinked:{
                            id:581,
                            transferId:49479,
                            deviceId:1073543,
                            container:"MSNU7031174",
                            currentState:1,
                            timeLinked:"2024-09-27 16:23:41",
                            lastBlitTime:"2024-09-30 17:20:14"
                        },
                        transferBlits:[
                            //{   id:465036,trLnkId:581, transferId:36365, deviceId:1073543, blitTime:"2024-09-27 16:35:33", lat:18.4581316,lng:-69.71066, heading:0, speed:0,distance:30420, distanceRemain:45976, travelTime:4141, timeRemain:2567, statusId:2, completed:0 },
                            { statusId:2, deviceId: 875156, lat: 18.4662666, lng: -69.9561022, heading: 125, completed:  0}, 

                        ]
                    }                                        

                ]
            }
        }
    }
 

}


    let salida = { data: { success: false } }

    switch (transfer_id) {

        case 3:
            salida = output1;


            var concat=output1

            output2.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.forEach(elem=>{
                 concat.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.push(elem)
            })        

            salida = concat;
            break;

        case 33:
            salida = output1;


            var concats=output1

            output2.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.forEach(elem=>{
                 concats.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.push(elem)
            })        

            salida = concats;
            break;

        case 2:
            salida =output1
            break;

        case 22:
            salida =output1
            break;

        case 5:

            var temp=output1

            output3.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.forEach(elem=>{
                 temp.data.clientFullTransferBlits.fullTransferBlits.transferLnkBlits.push(elem)
            })        

            salida = temp;

            break;

        case 1:
            salida = output2;
            break;
        case 0:
            salida = output4;
            break;
        case 48542:
            salida = output2;
            break;
        case 49479:
            salida = output3; //aqui 2
            break;

        default:
            salida = output2;
            break;
    }

    return salida

}
