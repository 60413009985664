let hoy = new Date();
let ayer = new Date(hoy.getTime() - (24 * 60 * 60 * 1000) * 2);


  import axios from 'axios';

 // axios.defaults.withCredentials = true; // Permite enviar credenciales como cookies

  const backEnd = process.env.VUE_APP_API; 
  //const backEnd= "http://10.0.0.172:9413";


//-----------  <<<<<<<< Prod >>>>>>>> ----------------------

/*
  import axios from 'axios';
  const backEnd = process.env.VUE_APP_API;        
//const backEnd= "http://10.0.0.172:9000";
*/

//----------- <<<<<<<<<  Test   >>>>>>>>>> ----------------------


import { 
     statesCountGeneral, statesCountGeneralFrame, transfersList, 
    transfersListFrame, transferBlitslist, devicesAllStats  }  //
from './fakeAPI.js' //authLogin candadoList


//------------- | metodos |-------------------------

export async function transfers_list(hash) {

    let data = { success: false };


    await transfersList(hash).then(response => {
    //await axios.get(backEnd + `/v1/transfers/list/${hash}`).then(response => {

        if (response.data.success) {
            data = response.data
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });

    return data
}

export async function auth(params) {

    let data = { success: false };

        //await authLogin(params.username, params.password ).then(response => {  { withCredentials: true }
        await axios.post(backEnd + `/v1/auth/login`, params ).then(response => {

        console.log(response.data);
    

        if (response.data) {

            data = { success: true };

        }


         }).catch(error => {
            console.log(error)
            return data = false
        });

    return data
}

export async function devicesAll(hash) {

    console.log(hash)

    let data = { success: false };

    //await candadoList(hash).then(response => {
    await axios.get(backEnd + `/v1/devices/all`).then(response => { //{ withCredentials: true }

        console.log(response.data)


        if (response.data.success) {
            data = response.data
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });

    return data
}


export async function transfers_listFrame(hash) {


    let data = { success: false };


    await transfersListFrame(hash, ayer.toISOString(), hoy.toISOString()).then(response => {
    //await axios.get(backEnd + `/v1/transfers/list/${hash}/${ayer.toISOString()}/${hoy.toISOString()}`).then(response => {


        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {

            data = response.data
            // console.log("No hay transfersList")
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });

    return data
}

export async function transfers_listRange(hash, from, to) {


    let data = { success: false };

    await transfersListFrame(hash, from, to).then(response => {
    //await axios.get(backEnd + `/v1/transfers/list/${hash}/${from}/${to}`).then(response => {
        
        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {

            data = response.data
            // console.log("No hay transfersList")
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });

    return data
}






export async function counterGeneral(hash) {

    let data = { success: false };

    await statesCountGeneral(hash).then(response => {
    //await axios.get(backEnd + `/v1/states/count/${hash}`).then(response => {


        if (response.data.success) {
            data = response.data
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });


    return data
}

export async function counterCandado(hash) {

    let data = { success: false };

    await devicesAllStats(hash).then(response => {
    //await axios.get(backEnd + `/v1/devices/allStats/${hash}`).then(response => {


        if (response.data.success) {
            data = response.data
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });


    return data
}

export async function counterGeneralFrame(hash) {

    let data = { success: false };

    await statesCountGeneralFrame(hash, ayer.toISOString(), hoy.toISOString()).then(response => {
    //await axios.get(backEnd + `/v1/states/count/${hash}/${ayer.toISOString()}/${hoy.toISOString()}`).then(response => {

        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {

            data = response.data
            //console.log("no existe")
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });


    return data
}

export async function counterGeneralRange(hash, from, to) {

    let data = { success: false };

    await statesCountGeneralFrame(hash, from, to).then(response => {
    //await axios.get(backEnd + `/v1/states/count/${hash}/${from}/${to}`).then(response => {


        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {

            data = response.data
            //console.log("no existe")
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });


    return data
}




export async function blitsTranfers(hash, transfer_id) {

    let data = { success: false };

    await transferBlitslist(hash, transfer_id).then(response => {
    //await axios.get(backEnd + `/v1/transferBlits/list/${hash}/${transfer_id}`).then(response => {


        if (response.data.success) {
            data = response.data
        } else if (response.data.note) {

            data = response.data
            console.log("no existe")
        }

        // console.log(response.data)

    }).catch(error => {
        console.log(error)
    });

    return data
}


/*export async function transfers_list(params) {
    let data = []
    await axios.post(backEnd + '/v1/transfers/list', params).then(response => {
        data = response.data
    }).catch(error => {
        console.log(error)
        return data = false
    });
    return data
}*/
