<template>
	<div class="AreaTrabajoPage">
		<div>Hola Historial</div>

	</div>
</template>

<style scoped>
.AreaTrabajoPage {
	width: auto;
	display: flex;
	flex-direction: column;

	/*background-color: pink;*/

}
</style>