<template>
  <div>
    <h1>Conexión en Tiempo Real con Atmosphere.js (Navixy) Mejorado</h1>

    <button @click="connectWebSocket">Conectar</button>

    <div class="container">
      <div class="json-container">
        <pre>{{ respuesta }}</pre>
      </div>
    </div>
  </div>
</template>

<script setup>
// Importa atmosphere.js y ref de Vue
import atmosphere from 'atmosphere.js';
import { ref, onMounted, onBeforeUnmount } from 'vue';

let subscriber = null;
let respuesta = ref();
let isConnected = ref(false);  // Estado de la conexión

const reconnectAttempts = ref(0);
const maxReconnectAttempts = 5;

const request = {
  url: `https://${process.env.VUE_APP_SOCKET_SERVER}`,
  contentType: 'application/json',
  logLevel: 'debug',
  transport: 'websocket',
  trackMessageLength: false,
  reconnectInterval: 2000, // Intervalo para reintentos de reconexión

  onOpen: function (r) {
    console.log('onOpen', r);
    isConnected.value = true;
    reconnectAttempts.value = 0; // Resetear los intentos
    sendSubsrcibeRequest(); // Enviar la solicitud de suscripción
  },
  onReopen: function (r) {
    console.log('onReopen', r);
    sendSubsrcibeRequest(); // Enviar la solicitud de suscripción al reconectar
  },
  onMessage: function (msg) {
    const parsedMessage = JSON.parse(msg.responseBody); // Parsear una sola vez
    console.log('onMessage', parsedMessage);
    respuesta.value = parsedMessage;
  },
  onClientTimeout: function (r) {
    console.log('onClientTimeout', r);
  },
  onTransportFailure: function (errorMsg, request) {
    console.log('onTransportFailure', errorMsg, request);
  },
  onClose: function (r) {
    console.log('onClose', r);
    isConnected.value = false;
    handleReconnect();
  },
  onError: function (r) {
    console.log('onError', r);
  },
  onReconnect: function (request, response) {
    console.log('onReconnect', response);
  }
};

// Función para conectar o reconectar al WebSocket
function connectWebSocket() {
  if (!subscriber) {
    console.log('Conectando WebSocket...');
    subscriber = atmosphere.subscribe(request);
  }
}

// Lógica de reintentos para reconectar
function handleReconnect() {
  if (reconnectAttempts.value < maxReconnectAttempts) {
    setTimeout(() => {
      reconnectAttempts.value++;
      console.log(`Intento de reconexión #${reconnectAttempts.value}`);
      connectWebSocket();
    }, reconnectAttempts.value * 2000); // Exponencial backoff
  } else {
    console.log('Máximo de intentos de reconexión alcanzado');
  }
}

// Enviar solicitud de suscripción
function sendSubsrcibeRequest() {
  console.log('Enviando solicitud de suscripción al WebSocket');

  const message = {
    action: 'subscribe',
    hash: process.env.VUE_APP_HASH,
    requests: [
      {
        type: 'state',
        trackers: [1010203, 474751, 1007834, 888433, 3076457],
        rate_limit: '5s',
        format: 'compact'
      }
    ]
  };

  if (subscriber && isConnected.value) {
    subscriber.push(JSON.stringify(message));
  }
}

// Detectar cuando la red se vuelve a conectar
function handleOnline() {
  console.log('La red está disponible. Intentando reconectar WebSocket...');
  if (!isConnected.value) {
    connectWebSocket();
  }
}

// Detectar cuando la red se desconecta
function handleOffline() {
  console.log('La red ha sido desconectada');
  isConnected.value = false;
}

// Configuración de eventos del navegador
onMounted(() => {
  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);
});

// Limpiar la suscripción cuando el componente se destruya
onBeforeUnmount(() => {
  window.removeEventListener('online', handleOnline);
  window.removeEventListener('offline', handleOffline);

  if (subscriber) {
    subscriber.close();
  }
});
</script>

<style scoped>
/* Estilos específicos para este componente */
button {
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

div {
  margin-top: 20px;
}

.json-container {
  font-family: "Courier New", monospace;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre {
  color: #333;
  line-height: 1.6;
  overflow-x: auto;
}
</style>
